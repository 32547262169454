import axios from "axios"
import { useEffect, useState, useRef } from "react"
import {
  SearchOutlined,
  EditOutlined,
  WhatsAppOutlined,
  CheckOutlined,
  CloseCircleTwoTone,
  ClockCircleTwoTone,
  ReloadOutlined,
  ScissorOutlined,
  BankOutlined,
  RocketTwoTone,
  StarFilled,
  HistoryOutlined,
  NodeIndexOutlined,
  SmileOutlined,
  StarTwoTone,
  BarsOutlined,
  PropertySafetyTwoTone,
  PhoneOutlined,
  VideoCameraTwoTone,
} from "@ant-design/icons"
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tooltip,
  Modal,
  message,
  Empty,
  Timeline,
  Checkbox,
  List,
  Typography,
  notification,
  Tag,
  Segmented,
  TimePicker,
  ConfigProvider,
  Alert,
} from "antd"

import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import { Form } from "antd"
import { useAuth } from "../../contexts/authContext"
import useView from "../../hooks/useView"
import FamLogo from "../../images/fam-logo.jpg"
import { socket } from "../../util/socket"
import { logDOM, render } from "@testing-library/react"
import notify from "../../Sounds/sound.mp3"
import { Howl } from "howler"
import { addDays } from "date-fns"
const { Option } = Select;
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}
const LeadsCRM = () => {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([])
  const [tag, setTag] = useState(false)
  const [errorState, setErrorState] = useState(null)
  const [utm, setUtm] = useState(null)
  const [inputValue, setInputValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const { Text } = Typography
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const [edit, setEdit] = useState({
    id: "test",
    name: "test",
    orderId: "test",
  })
  const { currentUser, roles } = useAuth()
  const isPOCViewable =
    roles.includes("SALES_ADMIN") ||
    roles.includes("SUPPORT") ||
    roles.includes("OPERATIONS")
  const isEditable = roles.includes("ADMIN") || roles.includes("SALES_B2C")
  const isAdmin = roles.includes("ADMIN")
  const [messageApi, contextHolder] = message.useMessage()
  const [NotifyApi, contextNofify] = notification.useNotification()
  const [system, setSystem] = useState([])
  const searchInput = useRef(null)
  const { isMobile } = useView()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalRefferalOpen, setIsModalRefferalOpen] = useState(false)
  const [refferal, setRefferal] = useState(undefined)
  const [showHistory, setShowHistory] = useState(undefined)
  const [viewType, setViewType] = useState("UNTOUCHEDLEAD")
  const [form] = Form.useForm()
  const [oldRecord, setOldRecord] = useState()
  const [updateDate, setUpdateDAte] = useState() /// to show followup editing field

  const [callInitiated, setCallInitiated] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const showRefferalModal = async (record) => {
    // console.log(record);
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/refferal`
      const res = await axios.put(url, {
        email: record.email,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      // console.log(res.data.data.referalcode.code)
      setRefferal(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
    setIsModalRefferalOpen(true)
  }
  const handleRefferalOk = () => {
    setIsModalRefferalOpen(false)
  }
  const handlerefferalCancel = () => {
    setIsModalRefferalOpen(false)
  }
  const onReset = () => {
    form.resetFields()
  }
  const getPOC = () => {
    const arr = []
    for (const key in constants.idToFilter) {
      arr.push({
        text: constants.idToFilter[key],
        value: key,
      })
    }
    return arr
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={
            dataIndex === "nextFollowUp" ? "YYYY-MM-DD" : `Search ${dataIndex}`
          }
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })
  const systemUser = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/systemuser`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setSystem(res.data.doc)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const handleSubmit = async (record) => {
    console.log(record)
    console.log(oldRecord)
    if (!isAdmin) {
      if (!record?.leadType) {
        return messageApi.open({
          type: "error",
          content: "Please Update leadtype, comment and followup to save the lead",
        })
      }

      //save the user comment
      if (record?.leadType !== 'CALL NOT PICKED TEXT SENT' && record?.leadType !== 'INVALID NUMBER') {
        if (!record?.comments?.trim()) {
          return messageApi.open({
            type: "error",
            content: "Please Add Comment and Followup date to save the lead",
          })
        }
      }
      if (record?.leadType !== oldRecord?.leadType) {
        console.log("calles");
        if (record?.comments === oldRecord?.comments && (record?.leadType !== 'CALL NOT PICKED TEXT SENT' && record?.leadType !== 'INVALID NUMBER')) {
          return messageApi.open({
            type: "error",
            content: "Please Update Comment and Followup Date to save the lead",
          })
        }
      }
      if (record?.leadType !== oldRecord?.leadType) {
        if (record?.nextFollowUp?.toString() === oldRecord?.nextFollowUp?.toString() && (record?.leadType === 'HOT' || record?.leadType === 'COLD')) {
          return messageApi.open({
            type: "error",
            content: "Please Update Followup to save the lead",
          });
        }
      }
    }
    setLoading(true)
    try {
      record.new = false
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/marketingData`
      const res = await axios.put(url, {
        user: record,
        email: currentUser.email,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const onFinish = async (values) => {
    console.log(values)
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/user/message/sendWPAfterCall`
      const res = await axios.post(url, {
        name: edit.name,
        id: edit.id,
        phone: values.Phone,
        orderId: edit.orderId,
        preBook: values.preBook,
        category: values.category,
        offerId:
          values.Offer == undefined || values.Offer == ""
            ? undefined
            : values.Offer,
        // offerId: NO ? undefined : offerId,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      // alert("Message Sent Successfully")
      messageApi.open({
        type: "success",
        content:
          values.Offer == undefined || values.Offer == ""
            ? "Message sent successfully"
            : "Message sent successfully with offer",
      })
      handleOk()
      setLoading(false)
    } catch (error) {
      console.log(error)
      // alert("Error", error.message)

      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      handleOk()
      setLoading(false)
    }
  }

  const pocPrint = (poc) => {
    if (poc === "649bcba1e6da06f4fae8653e") {
      return "Anshul"
    }
    if (poc === "649bcba1e6da06f4fae8653f") {
      return "Akshay"
    }
    if (poc === "649bcba1e6da06f4fae86540") {
      return "Shyam"
    }
    if (poc === "64a3b733a8a16de2e4894833") {
      return "Kuldeep"
    }
    if (poc === "64a11376a8a16de2e4894825") {
      return "Deepika"
    }
    if (poc === "651e5ee97e6d414554fa3f2e") {
      return "Naama"
    }
    if (poc === "652833fe81efc0b4ddaa3f50") {
      return "Archi"
    }
    if (poc === "6528342581efc0b4ddaa3f51") {
      return "Divyank"
    }
    if (poc === "6528345881efc0b4ddaa3f52") {
      return "Chanchal"
    }
    if (poc === "6528348381efc0b4ddaa3f53") {
      return "Sakshi"
    }
    if (poc === "652834ad81efc0b4ddaa3f54") {
      return "Richard"
    }
    if (poc === "652834de81efc0b4ddaa3f55") {
      return "Himani"
    }
    if (poc === "652997a5e13119fcf267b9fa") {
      return "Kashyap"
    }
    if (poc === "65299825e13119fcf267b9fb") {
      return "Nehal"
    }
    if (poc === "65299825e13119fcf267b9fc") {
      return "Yashashree"
    }
    if (poc === "65299825e13119fcf267b9fd") {
      return "Aastha"
    }
    if (poc === "65323853e8bdc11d099c8baa") {
      return "Dipali Yadav"
    }
    if (poc === "65323853e8bdc11d099c8bab") {
      return "Swati Malakar"
    }
    if (poc === "65323853e8bdc11d099c8bac") {
      return "Chhavani Karma"
    }
    if (poc === "65323853e8bdc11d099c8bad") {
      return "Vedansh Naik"
    }
    if (poc === "65323853e8bdc11d099c8bae") {
      return "Aakash Yadav"
    }
    if (poc === "65323853e8bdc11d099c8baf") {
      return "Shailendra Kewat"
    }
    if (poc === "65323853e8bdc11d099c8bb0") {
      return "Srashti Sahu"
    }
    if (poc === "65323853e8bdc11d099c8bb1") {
      return "Chanchal thakur"
    }
    if (poc === "65323853e8bdc11d099c8bb2") {
      return "Ramesh Kag"
    }
    if (poc === "65323853e8bdc11d099c8bb3") {
      return "Arshdeep Singh"
    }
    if (poc === "65323853e8bdc11d099c8bb4") {
      return "Ashwini"
    }
    if (poc === "65323853e8bdc11d099c8bb5") {
      return "Suresh Patidar"
    }
    if (poc === "65323853e8bdc11d099c8bb6") {
      return "Shyam Patel"
    }
    if (poc === "65323853e8bdc11d099c8bb7") {
      return "Gaurav Soni"
    }
    if (poc === "65337a4143d2714c06c1c184") {
      return "Richa Nema"
    }
    if (poc === "65571487c57d2975cd00176a") {
      return "Yashu Upadhayay"
    }
    if (poc === "659bd0ec2b51a131a915d7c3") {
      return "Saloni chouhan"
    }
    if (poc === "659bd1042b51a131a915d7c4") {
      return "Nishchay chadar"
    }
    if (poc === "66825c66fc2385731821a8d0") {
      return "Shikha"
    }
    return "N/A"
  }

  const sendMessage = async (record) => {
    // const offerId = prompt(`Want to apply offer Link`)
    // const phone = prompt(`Enter custom number`)
    // let PH = false
    // // console.log(PH);
    // const NO = offerId === "n" || offerId === "N"
    // if(phone=="n"||phone=="N")
    // {
    //   PH=false
    // }
    // else{
    //   PH=true
    // }
    // setLoading(true)
    // try {
    //   const url = `${constants.DOMAIN}/api/v1/users/message/sendWPAfterCall`
    //   const res = await axios.post(url, {
    //     name: record.name,
    //     phone: PH ?phone:record.phone,
    //     orderId: record.razorpayOrderId,
    //     offerId: NO ? undefined : offerId,
    //   })
    //   alert("Message Sent Successfully")
    //   setLoading(false)
    // } catch (error) {
    //   console.log(error)
    //   alert("Error", error.message)
    //   setLoading(false)
    // }
    onReset()
    setEdit({
      id: record._id,
      name: record?.name,
      orderId: record?.razorpayOrderId,
    })
    form.setFieldValue("Phone", record.countryCode + record.phone)
    showModal()
  }

  const telecmiCallLead = async (countryCode, phone) => {
    // console.log('lead_contact-', countryCode + phone)
    try {
      setCallInitiated(true)
      const url = `https://rest.telecmi.com/v2/webrtc/click2call`
      const res = await axios.post(url, {
        "user_id": constants.telecmiIds[currentUser.uid].userId,
        "secret": process.env.REACT_APP_TELECMI_SECRET,
        "to": parseInt(countryCode+phone),
        "extra_params": {"crm": true},
        "webrtc": true,
        "followme": true,
        "callerid": constants.telecmiIds[currentUser.uid].calerId
      })
      console.log('dialer-res-', res)
      messageApi.open({
        type: "success",
        content:'Call Initiated, You will Receive a call on your dialer shortly',
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
      alert("Error", error.message)
      messageApi.open({
        type: "error",
        content:'Something Went Wrong',
      })
      setLoading(false)
    }
  }

  function replaceMiddleWithHash(phoneNumber) {
    if (phoneNumber.length < 10) {
      return "Invalid phone number"
    }
    const middleDigits = phoneNumber.substring(3, 9) // Extracting middle six digits
    const replacedPhoneNumber = phoneNumber.replace(middleDigits, "######") // Replacing middle digits with "#"
    return replacedPhoneNumber
  }
  const renderStatus = (_, record) => {
    if (isPOCViewable) return

    if (record.leadType === "DIRECT") {
      return <RocketTwoTone twoToneColor="green" style={{ fontSize: "25px" }} />
    }
    if (record.leadType === "CONVERTED")
      return (
        <CheckOutlined
          twoToneColor="lightgreen"
          style={{ color: "lightGreen", fontSize: "25px" }}
        />
      )
    if (record?.schoolId) {
      return (
        <PropertySafetyTwoTone
          twoToneColor="red"
          style={{ fontSize: "20px" }}
        />
      )
    }

    if (
      record?.utm_medium?.toLowerCase().includes("uae")
    ) {
      return (
        <img width="25" height="25" src="https://img.icons8.com/external-mixed-line-solid-yogi-aprelliyanto/64/external-burj-al-arab-landmark-and-monument-mixed-line-solid-yogi-aprelliyanto.png" alt="external-burj-al-arab-landmark-and-monument-mixed-line-solid-yogi-aprelliyanto" />
      )
    }

    if (
      record?.utm_source?.toLowerCase().includes("demo") || record?.utm_medium?.toLowerCase().includes("demo") || record?.utm_content?.toLowerCase().includes("demo") || record?.utm_campaign?.toLowerCase().includes("demo") || record?.placement?.toLowerCase().includes("demo")
    ) {
      return (
        <VideoCameraTwoTone 
        twoToneColor="purple"
        style={{ fontSize: "20px" }}
        />
      )
    }


    if (
      record?.utm_medium?.toLowerCase()?.includes("parent") ||
      record?.utm_campaign?.includes("120210127451370759")
    ) {
      return (
        <img
          width="25"
          height="25"
          src="https://img.icons8.com/stencil/32/dryclean-with-any-solvent-except-trichloroethylene.png"
          alt="dryclean-with-any-solvent-except-trichloroethylene"
        />
      )
    }
    if (record.profile && record.programType !== "MASTERUNION") {
      return <BankOutlined />
    }

    if (record.leadType === "NOT INTERESTED" || record.leadType === "JUNK") {
      return (
        <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: "20px" }} />
      )
    }

    if (record.new) {
      if (record.oldUser) {
        return (
          <>
            <Badge color="blue" />
            <Tooltip title="Repeating user :)">
              <StarFilled style={{ color: "green" }} />
            </Tooltip>
          </>
        )
      } else {
        return <Badge color="blue" />
      }
    }

    if (
      record.nextFollowUp &&
      new Date(record.nextFollowUp).toLocaleDateString() ===
      new Date().toLocaleDateString()
    )
      return (
        <ClockCircleTwoTone
          twoToneColor="orange"
          style={{ fontSize: "25px" }}
        />
      )
  }
  function replaceName(str) {
    if (str.length <= 2) {
      return str // Nothing to replace if the string has 2 or fewer characters
    }

    const firstChar = str.charAt(0)
    const lastChar = str.charAt(str.length - 1)
    const middleHashes = "#".repeat(str.length - 2) // Generate "#" symbols for the middle

    return firstChar + middleHashes + lastChar
  }
  const columns = [
    {
      title: "",
      width: 50,
      render: (_, record) => {
        return <Space>{renderStatus(_, record)}</Space>
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      //   fixed: "left",
      sorter: false,
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            {record.categoryCode !== "COUNSELLING" ? (
              <Text
                copyable={{
                  text: `${record?.categoryCode?.includes("12PASS")?"https://www.12thpass.co":"https://youvah.com"}/sip/payment/${record._id}`,
                }}
              >
                {" "}
                {record.name}{" "}
              </Text>
            ) : (
              <> {record.name} </>
            )}
            {record.source === "FAMPAY" && <img src={FamLogo} width={"20px"} />}
          </div>
        )
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: false,
      //   fixed: "left",
      render: (phone, record) => (
        <>
          {`(${record.countryCode})`}{" "}
          {isPOCViewable ? replaceMiddleWithHash(phone) : phone}
        </>
      ),
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Category",
      dataIndex: "categoryCode",
      sorter: false,
      width: 150,
      render: (data, record) => (
        <>
          {record.programType === "PROCOURSE" ? (
            <>
              <Tag color={"volcano"}>{data}</Tag>
            </>
          ) : record.programType === "MASTERUNION" ? (
            <>
              <Tag color={"#f9d133"}>{data}</Tag>
            </>
          ) : record.programType === "MENTORNSHIP" ? (
            <>
              <Tag color={"green-inverse"}>{data}</Tag>
            </>
          ) : record.categoryCode === "COUNSELLING" ? (
            <>
              <Tag color={"purple-inverse"}>{data}</Tag>
            </>
          ) : (
            <>{data}</>
          )}
        </>
      ),
      ...getColumnSearchProps("categoryCode"),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      sorter: false,
      width: 150,
      render: (data, record) => (
        <>
          {data ? (
            <>
              <Tag color={data === "HYBRID" ? "purple-inverse" : "#b658ff"}>
                {data}
              </Tag>
            </>
          ) : record.programType === "MASTERUNION" ? (
            <>
              <Tag color={"cyan"}>Offline</Tag>
            </>
          ) : (
            <>
              <Tag color={"red"}>Not Selected</Tag>
            </>
          )}
        </>
      ),
      ...getColumnSearchProps("plan"),
    },
    isMobile
      ? {
        width: 0,
      }
      : {
        title: "DOR (MM/DD/YY)",
        dataIndex: "createdAt",
        sorter: false,
        render: (date) => (
          <>
            {new Date(date).toLocaleDateString()}
            <br />
            <div style={{ color: "lightgray" }}>
              {new Date(date).toLocaleTimeString()}
            </div>
          </>
        ),
      },
    {
      title: "Call Recipient",
      width: 130,
      dataIndex: "callRecipient",
      filters: [
        {
          text: "Parent",
          value: "PARENT",
        },
        {
          text: "Student",
          value: "STUDENT",
        },
      ],
      render: (callRecipient, record) => {
        return (
          <Select
            size="small"
            defaultValue={callRecipient || ""}
            style={{
              width: 105,
            }}
            onChange={(e) => {
              record.callRecipient = e
              console.log(record)
            }}
            options={[
              {
                value: "STUDENT",
                label: "Student",
              },
              {
                value: "PARENT",
                label: "Parent",
              },
            ]}
          />
        )
      },
    },
    {
      title: "Lead Type",
      dataIndex: "leadType",
      width: 160,
      filters: isAdmin ? [
        {
          text: "INVALID NUMBER",
          value: "INVALID NUMBER",
        },
        {
          text: "CALL NOT PICKED TEXT SENT",
          value: "CALL NOT PICKED TEXT SENT",
        },
        {
          text: "HOT",
          value: "HOT",
        },
        {
          text: "WARM",
          value: "WARM",
        },
        {
          text: "COLD",
          value: "COLD",
        },
        {
          text: "WARM",
          value: "WARM",
        },
        {
          text: "NOT INTERESTED",
          value: "NOT INTERESTED",
        },
        {
          text: "AGE NOT VALID",
          value: "AGE NOT VALID",
        },
        {
          text: "JUNK",
          value: "JUNK",
        },
        {
          text: "CONVERTED",
          value: "CONVERTED",
        },
        {
          text: "DIRECT",
          value: "DIRECT",
        },
        {
          text: "UNTOUCHED",
          value: "",
        },
      ] : [{
        text: "INVALID NUMBER",
        value: "INVALID NUMBER",
      },
      {
        text: "CALL NOT PICKED TEXT SENT",
        value: "CALL NOT PICKED TEXT SENT",
      },
      {
        text: "HOT",
        value: "HOT",
      },
      {
        text: "WARM",
        value: "WARM",
      },
      {
        text: "COLD",
        value: "COLD",
      },
      {
        text: "WARM",
        value: "WARM",
      },
      {
        text: "NOT INTERESTED",
        value: "NOT INTERESTED",
      },
      {
        text: "AGE NOT VALID",
        value: "AGE NOT VALID",
      },
      {
        text: "JUNK",
        value: "JUNK",
      },
      {
        text: "UNTOUCHED",
        value: "",
      },
      ],
      render: (leadType, record) => {
        return (
          <Select
            size="small"
            defaultValue={leadType || ""}
            style={{
              width: 140,
            }}
            onChange={(e) => {
              let oldRecord = { ...record }
              setOldRecord(oldRecord)
              record.leadType = e
            }}
            options={isAdmin ? [
              {
                value: "INVALID NUMBER",
                label: "INVALID NUMBER",
              },
              {
                value: "CALL NOT PICKED TEXT SENT",
                label: "CALL NOT PICKED TEXT SENT",
              },
              {
                value: "HOT",
                label: "HOT",
              },
              {
                value: "WARM",
                label: "WARM",
              },
              {
                value: "COLD",
                label: "COLD",
              },
              {
                text: "WARM",
                value: "WARM",
              },
              {
                value: "NOT INTERESTED",
                label: "NOT INTERESTED",
              },
              {
                value: "AGE NOT VALID",
                label: "AGE NOT VALID",
              },
              {
                value: "JUNK",
                label: "JUNK",
              },
              {
                value: "CONVERTED",
                label: "CONVERTED",
              },
              {
                value: "DIRECT",
                label: "DIRECT",
              },
            ] : [{
              value: "INVALID NUMBER",
              label: "INVALID NUMBER",
            },
            {
              value: "CALL NOT PICKED TEXT SENT",
              label: "CALL NOT PICKED TEXT SENT",
            },
            {
              value: "HOT",
              label: "HOT",
            },
            {
              value: "WARM",
              label: "WARM",
            },
            {
              value: "COLD",
              label: "COLD",
            },
            {
              value: "NOT INTERESTED",
              label: "NOT INTERESTED",
            },
            {
              value: "AGE NOT VALID",
              label: "AGE NOT VALID",
            },
            {
              value: "JUNK",
              label: "JUNK",
            },
            ]}
          />
        )
      },
    },
    {
      title: "Next Follow up",
      dataIndex: "nextFollowUp",
      sorter: false,
      width: "300",
      render: (nextFollowUp, record) => {

        const handleEdit = () => {
          setUpdateDAte(record.phone)
        };

        return (
          <>

            {record.phone === updateDate || !nextFollowUp ? (
              <Tooltip title="Edit Next Follow-Up">
                <DatePicker
                  showTime
                  size="small"
                  onChange={(v) => {
                    if (!v) return
                    //set in this way to make Date filter work
                    // setOldRecord(record)
                    record.nextFollowUp = new Date(v)
                    setUpdateDAte('')
                  }}
                  //   onOk={(v) => {
                  //     console.log(v.toISOString())
                  //   }}
                  defaultValue={nextFollowUp ? dayjs(nextFollowUp) : ""}
                />
              </Tooltip>
            ) : <>
              <div style={{ cursor: 'pointer', color: `${new Date(nextFollowUp).getTime() < new Date().getTime() ? 'white' : ''}`, background: `${new Date(nextFollowUp).getTime() < new Date().getTime() ? 'red' : ''}`, padding: `${new Date(nextFollowUp).getTime() < new Date().getTime() ? '10px' : ''}`, borderRadius: `${new Date(nextFollowUp).getTime() < new Date().getTime() ? '10px' : ''}` }} onClick={handleEdit}>
                {nextFollowUp && dayjs(nextFollowUp).format('MMMM D, YYYY')}
                {console.log(nextFollowUp)}
                <br />
                <Space>
                  <span style={{ color: 'lightgray' }}>
                    {nextFollowUp && dayjs(nextFollowUp).format('h:mm A')}
                  </span>
                </Space>
              </div>
            </>}


          </>
        );
      },
      // Render the filter dropdown with date picker and time picker
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            value={selectedKeys[0] ? [selectedKeys[0], selectedKeys[1]] : []}
            onChange={([from, to]) => setSelectedKeys([from, to])}
            onOk={() => confirm()}
          />
          <Space>
            <button
              type="button"
              onClick={() => {
                setSelectedKeys([]);
                clearFilters();
              }}
              style={{ width: 90 }}
            >
              Reset
            </button>
            <button
              type="button"
              onClick={() => confirm()}
              style={{ width: 90 }}
            >
              OK
            </button>
          </Space>
        </div>
      ),
      // Customize the filter icon
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      render: (comments, record) => {
        return (
          <TextArea
            rows={3}
            // value={comments}
            defaultValue={comments}
            onChange={(e) => {
              console.log(e.target.value)
              // setOldRecord(record)
              record.comments = e.target.value
            }}
          />
        )
      },
    },
    isPOCViewable
      ? {
        title: "POC",
        dataIndex: "POC_V1",
        filters: (system || []).map((d) => ({
          value: d._id,
          text: d.name,
        })),
        render: (data, record) => {
          return <>{constants.idToPOC[data]}</>
        },
        width: 150,
      }
      : {
        width: 0,
      },
    isAdmin
      ? {
        title: "POC",
        dataIndex: "POC_V1",
        filters: (system || []).map((d) => ({
          value: d._id,
          text: d.name,
        })),
        render: (data, record) => {
          return (
            <Select
              size="small"
              defaultValue={constants.idToPOC[data] || ""}
              style={{ width: 100 }}
              onChange={(e) => {
                record.POC_V1 = e
                console.log(record)
              }}
              options={(system || []).map((d) => ({
                value: d._id,
                label: d.name,
              }))}
            />
          )
        },
        width: 150,
      }
      : {
        width: 0,
      },
    {
      title: "Email",
      dataIndex: "email",
      render: (data, record) => {
        return <>{isPOCViewable ? replaceName(record.email) : record.email}</>
      },
      ...getColumnSearchProps("email"),
      width: 150,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <>
          <Space direction="vertical" size={"small"}>
            {isEditable ? (
              <Tooltip title="save">
                <Button
                  type="link"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => handleSubmit(record)}
                />
              </Tooltip>
            ) : (
              ""
            )}
            {record.categoryCode !== "COUNSELLING" && (
              <Tooltip title="Send WhatsApp">
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => sendMessage(record)}
                  icon={<WhatsAppOutlined style={{ color: "#45C153" }} />}
                />
              </Tooltip>
            )}
            <Tooltip title="Referral">
              <Button
                type="text"
                shape="circle"
                onClick={() => showRefferalModal(record)}
                icon={<NodeIndexOutlined style={{ color: "#971eff" }} />}
              />
            </Tooltip>
            <Tooltip title="Show History">
              <Button
                type="text"
                shape="circle"
                onClick={() => setShowHistory(record.history || [])}
                icon={<HistoryOutlined style={{ color: "red" }} />}
              />
            </Tooltip>
            <Tooltip title="Call Lead">
              <Button
                type="text"
                shape="circle"
                onClick={() => telecmiCallLead(record.countryCode || "91", record.phone || "9575694340")}
                icon={<PhoneOutlined style={{ color: "green" }} />}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ]

  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => setTableParams({ ...tableParams, filters: {} })}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
          loading={loading}
        >
          Reload
        </Button>
      </Space>
    )
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    fetchData()
  }

  const fetchData = async () => {
    
    setErrorState("")
    console.log("CAlled : ", tableParams)
    if(tag && !utm){
    return setErrorState('Month is required')
    }
    if(tag && !inputValue){
      return setErrorState('Tag Id is required')
      }
      setLoading(true)
    try {
      let query = []

      Object.keys(tableParams.filters).forEach((k) => {
        if (
          tableParams.filters[k] !== null &&
          tableParams.filters[k].length > 0
        ) {
          if (k === "name") {
            query.push(`${k}:sw=${tableParams.filters[k]}`)
          } else if (k === 'nextFollowUp') {
            query.push(`nextFollowUp:gte=${new Date(tableParams.filters[k][0]).toISOString()}&nextFollowUp:lt=${new Date(tableParams.filters[k][1]).toISOString()}`);
          } else {
            query.push(`${k}=${tableParams.filters[k]}`)
          }
        }
      })
      //   name={$regex:^fin,$options:'i'}&
      if (viewType === 'FOLLOWUP') {
        let sd = new Date();
        sd.setHours(0, 0, 0, 0);
        let ed = new Date();
        ed.setDate(ed.getDate() + 1);
        ed.setHours(0, 0, 0, 0);

        let sdISO = sd.toISOString();
        let edISO = ed.toISOString();

        query.push(`nextFollowUp:gte=${sdISO}&nextFollowUp:lt=${edISO}`);
      }
      
      if(viewType === 'UNTOUCHEDLEAD'){
        query.push("leadType:or=")
        query.push("leadType:or=null")
      }

      if(viewType === 'CALLNOT'){
        query.push("leadType=CALL NOT PICKED TEXT SENT")
      }
      if(tag){
        query.push(`pocTag=${utm + "_" + inputValue}`)
      }
      query =
        `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&firebaseId=${currentUser._delegate.uid}&` +
        query.join("&")
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/marketingData?${query}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setData(res.data.data.users)
      if(res?.data?.data?.users.length === 0){
        setErrorState('No Data Found')
      }
      setLoading(false)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.results,
        },
      })
    } catch (error) {
      setLoading(false)
      setErrorState(error)
    }
  }
  // console.log(data)
  console.log(tableParams.filters);
  useEffect(() => {
    if (viewType !== 'LEAD') return
    socket.on("new_lead", (raw) => {
      //updated Table Data
      if (JSON.stringify(tableParams.filters) === JSON.stringify({})) {
        setData((oldArray) => {
          // updateArr()
          const index = oldArray.findIndex(obj => obj?.email === raw.lead?.email || obj?.phone === raw.lead?.phone);
          if (index !== -1) {
            // If object with specified email is found, remove it
            oldArray.splice(index, 1);
            return [raw.lead, ...oldArray]
          } else {
            oldArray.pop()
            return [raw.lead, ...oldArray]
          }
        })
      } else {
        let update = false
        for (const key in tableParams.filters) {
          if (tableParams.filters[key] === '' && tableParams.filters[key].length === 0) {
            update = true
          }
        }
        if (true) {
          setData((oldArray) => {
            // updateArr()
            const index = oldArray.findIndex(obj => obj?.email === raw.lead?.email || obj?.phone === raw.lead?.phone);
            if (index !== -1) {
              // If object with specified email is found, remove it
              oldArray.splice(index, 1);
              return [raw.lead, ...oldArray]
            } else {
              oldArray.pop()
              return [raw.lead, ...oldArray]
            }
          })
        }
      }
      // play sound
      const sound = new Howl({
        src: notify,
      })
      sound.play()
      //pop notification
      NotifyApi.open({
        message: "New Lead Added",
        description: "",
        duration: 0,
        placement: "top",
        style: {
          backgroundColor: "#fff568",
          fontWeight: "bolder",
        },
        icon: (
          <SmileOutlined
            style={{
              color: "#971eff",
            }}
          />
        ),
      })
    })

    //payment

    socket.on("payment_successful", (raw) => {
      //updated Table Data
      //  setData(oldArray =>{
      //   let index = 0
      //   let reviewArray = oldArray || []
      //   index = reviewArray.findIndex((obj) => obj.email ==  raw.lead.email)
      //   if (index >= 0) {
      //     reviewArray[index].leadType = "CONVERTED"
      //   }
      //   return [...oldArray]});
      fetchData()
    })
  }, [socket])

  useEffect(() => {
    fetchData()
    systemUser()
  }, [
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
    tableParams.filters,
    viewType
  ])

  useEffect(() => {
    if(tag)fetchData()
  }, [
    tag,inputValue,utm
  ])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }
  return (
    <>
      {contextHolder}
      {contextNofify}

      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        {data && console.log("data set")}
        <Modal
          title="Whatapp Message and Offer Tab"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              name="Phone"
              label="Phone"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Offer"
              label="Offer"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
              // placeholder={edit.batchId}
              />
            </Form.Item>
            <Form.Item name="category" label="Category">
              <Select>
                <Select.Option value={undefined}>NONE</Select.Option>
                <Select.Option value="GD">GD (Graphic Design)</Select.Option>
                <Select.Option value="CW">CW (Content Writing)</Select.Option>
                <Select.Option value="VE">VE (Video Editing)</Select.Option>
                <Select.Option value="SMM">SMM (Social Media)</Select.Option>
                <Select.Option value="WD">WD (Web Dev)</Select.Option>
                <Select.Option value="PSY">PSY (Psychology)</Select.Option>
                <Select.Option value="FM">FM (Finance Managment)</Select.Option>
                <Select.Option value="BA">BA (Business Analytics)</Select.Option>
                <Select.Option value="GDPRO">
                  GDPRO (Graphics Design PRO)
                </Select.Option>
                <Select.Option value="DM">DM (Digital Marketing)</Select.Option>
                <Select.Option value="PSYPRO">PSYPRO (Psychology Pro)</Select.Option>
                <Select.Option value="CWPRO">CWPRO (Copy Writing Pro)</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="preBook"
              valuePropName="checked"
              wrapperCol={{
                offset: 5,
              }}
            >
              <Checkbox>It's a pre-book Link</Checkbox>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* show history modal */}
        <Modal
          title="User Registration Histroy"
          open={showHistory}
          onCancel={() => setShowHistory(undefined)}
          footer={[]}
        >
          {showHistory && showHistory.length > 0 ? (
            <Timeline
              mode="alternate"
              items={showHistory?.map((history) => {
                return {
                  children: `Regsitered on ${new Date(
                    history.createdAt
                  ).toLocaleDateString()} for ${history.categoryCode}`,
                }
              })}
            />
          ) : (
            <Empty description="No history found" />
          )}
        </Modal>

        {/* refferal modal */}
        <Modal
          title="Refferal"
          open={isModalRefferalOpen}
          onOk={handleRefferalOk}
          onCancel={handlerefferalCancel}
          footer={[]}
        >
          {refferal ? (
            <>
              Name : {refferal.referalcode?.owner.name}
              <br></br>
              Code :{" "}
              <Typography.Text copyable={refferal.referalcode?.code}>
                {refferal.referalcode?.code}
              </Typography.Text>
              <List
                header={<div>Reffered User</div>}
                // footer={<div>Footer</div>}
                // bordered
                dataSource={refferal.referalcode?.refferals}
                renderItem={(item) => (
                  <List.Item>
                    <Typography.Text>{item.user?.name}</Typography.Text>
                    <Typography.Text>{item.user?.phone}</Typography.Text>
                    <Typography.Text>{item.user?.email}</Typography.Text>
                  </List.Item>
                )}
              />
            </>
          ) : (
            <Empty description="No history found" />
          )}
        </Modal>

        <Row>
          <Col xs={24} md={20}>
            <div className="text-3xl">Leads CRM</div>
            <p className="text-lg-light">
              {new Date().toDateString()} - {new Date().toLocaleTimeString()}
            </p>
          </Col>
          <Col xs={24} md={4}>
             <Statistic
              title="Total Registrations"
              loading={loading}
              value={tableParams.pagination?.total}
              precision={0}
              valueStyle={{
                color: "blue",
              }}
              // prefix={<ArrowUpOutlined />}
              suffix="leads"
            /> 
          </Col>


        </Row>
        {errorState &&  <Alert message={errorState} type='error' banner  style={{fontWeight:"bold",marginBottom:"10px"}} />}
        <h1>Use below filters to find lead per 100 (Poc Tag)</h1>
        <Row gutter={16} className="mb-4">
        <Col span={6}>
          <Select
            placeholder="Select Month"
            style={{ width: '100%' }}
            onChange={(value) => setUtm(value) }
          >
            <Option value="">None</Option>
            {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
              <Option key={month} value={`${month.toUpperCase()}_2024`}>{month}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
            <Input
              placeholder="Enter Tag Id"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={() =>{
             setTag(true)
             }}>
            Fetch Data
          </Button>
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={() => {
            setTag(false)
            }}>
            Reset 
          </Button>
        </Col>
      </Row>

        <div style={{ marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '15px', width: 'fit-content' }}>        {renderHeader()}
          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemColor: '#971eff'
                },
              },
            }}
          >
            <Segmented
              onChange={(e) => setViewType(e)}
              options={[
                {
                  label: "Untouched Leads",
                  value: "UNTOUCHEDLEAD",
                  icon: <BarsOutlined />,
                },
                {
                  label: "Today Followups",
                  value: "FOLLOWUP",
                  icon: <ClockCircleTwoTone
                    twoToneColor="orange"
                    style={{ fontSize: "15px" }}
                  />,
                },
                {
                  label: "Worked Leads",
                  value: "LEAD",
                  icon: <BarsOutlined />,
                },
                {
                  label: "Call Not Picked",
                  value: "CALLNOT",
                  icon: <BarsOutlined />,
                },
              ]}
            />
          </ConfigProvider>
        </div>

        <Table
          theme="dark"
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          // title={renderHeader}
          pagination={tableParams.pagination}
          loading={loading}
          rowClassName="row"
          onChange={handleTableChange}
          size="medium"
          scroll={{
            x: 1500,
            // y: 500,
          }}
        />
      </div>
    </>
  )
}
export default LeadsCRM
